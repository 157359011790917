import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { deleteTrip, getTrip, getTrips } from '../../utils/API/trips';
import { TripForm } from './TripForm';
import { getSystem } from '../../utils/API/system';
import I3Modal from '../../components/Layout/I3Modal';
import BaseTable from '../../components/Table/BaseTable';
import prepareTableKilometers from './prepareTableKilometers';
import I3ModalConfirmation from '../../components/Layout/I3ModalConfirmation';
import _ from 'lodash';
import { UserContext } from '../../context/UserContext';
import { Unauthorized } from '../../components/Unauthorized';
import { currencyFormatter } from '../../components/Table/utility';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const fetchTripsData = async (setTrips, date) => {
  const tripsData = await getTrips(date);
  const orderedData = _.orderBy(tripsData, ['date'], ['asc']);
  setTrips(orderedData);
};

const Kilometers = (props) => {
  const {
    showModal,
    handleShowModal,
    handleHideModal,
    showModalTableConfirm,
    handleShowModalTableConfirm,
    handleHideModalTableConfirm,
    month,
    isReportConfirmed,
    tripsUpdated,
  } = props;
  const { user } = useContext(UserContext);

  const [trips, setTrips] = useState([]);
  const [system, setSystem] = useState({});
  const [currentTrip, setCurrentTrip] = useState(false);
  const updateTrips = () => fetchTripsData(setTrips, month);
  const { t } = useTranslation();

  const handleHideTripModal = () => {
    handleHideModal();
    setCurrentTrip(false);
    updateTrips().finally();
  };

  useEffect(() => getSystem().then(setSystem), []);

  useEffect(() => updateTrips(), [month]);

  useEffect(() => tripsUpdated(trips), [trips, tripsUpdated]);

  const prepareTable = prepareTableKilometers({
    trips,
    isReportConfirmed,
    setCurrentTrip,
    handleShowModal,
    handleShowModalTableConfirm,
  });

  const edit = (id) => {
    getTrip(id).then((trip) => {
      setCurrentTrip(trip);
      handleShowModal();
      return trip;
    });
  };

  const remove = (id) => {
    handleShowModalTableConfirm();
    /**
     *  in questo punto viene messo l'id all'interno di current trip.
     *  Non è corretto perchè formik si aspetta l'oggetto trip
     *  per ora abbiamo corretto l'errore resettando currentTrip sull'annulla della cancellazione.
     *  Ma va sistemato
     */
    setCurrentTrip(id);
  };

  const actions = {
    edit,
    remove,
  };

  const onConfirm = () => {
    deleteTrip(currentTrip).then(updateTrips);
    setCurrentTrip(false);

    handleHideModalTableConfirm();
  };

  const typeColumn = _.find(prepareTable.columns, { accessor: 'type' });
  if (typeColumn) {
    Object.assign(typeColumn, {
      Cell: (prop) => {
        const { cell } = prop;
        return <div>{t(cell.value)}</div>;
      },
    });
  }

  const onHideDeleteConfirmation = () => {
    setCurrentTrip(false);
    handleHideModalTableConfirm();
  };

  const tripSummary = () => {
    const item = trips.find((e) => e.id === currentTrip);
    if (!item) return '';
    const { date, description, kilometers, total_cost: cost } = item;
    return `${date} - ${description} - ${kilometers} km / ${currencyFormatter.format(
      cost
    )}`;
  };

  return user.is_employee ? (
    <>
      <BaseTable
        columns={prepareTable.columns}
        data={trips}
        hiddenColumns={prepareTable.hiddenColumns}
        columnOrder={prepareTable.columnOrder}
        actions={actions}
      />

      <I3ModalConfirmation
        show={showModalTableConfirm.trip}
        onHide={onHideDeleteConfirmation}
        title="Cancella chilometri"
        questionTitle="Vuoi cancellare i chilometri?"
        questionText={tripSummary()}
        onConfirm={onConfirm}
      />

      <I3Modal
        show={showModal}
        onHide={handleHideTripModal}
        title={currentTrip?.id ? 'Modifica chilometri' : 'Inserisci chilometri'}
        className="I3Modal-add-km"
      >
        <TripForm
          close={handleHideTripModal}
          kmCost={system.kilometer_cost}
          currentTrip={currentTrip}
          updateTrips={updateTrips}
          month={month}
        />
      </I3Modal>
    </>
  ) : (
    <Unauthorized />
  );
};

Kilometers.defaultProps = {
  showModal: false,
  handleShowModal: () => {},
  handleHideModal: () => {},
  showModalTableConfirm: {},
  handleShowModalTableConfirm: () => {},
  handleHideModalTableConfirm: () => {},
  tripsUpdated: () => {},
  month: moment(),
  isReportConfirmed: false,
};

Kilometers.propTypes = {
  showModal: PropTypes.bool,
  handleShowModal: PropTypes.func,
  handleHideModal: PropTypes.func,
  showModalTableConfirm: PropTypes.shape({
    trip: PropTypes.bool,
    expense: PropTypes.bool,
  }),
  handleShowModalTableConfirm: PropTypes.func,
  handleHideModalTableConfirm: PropTypes.func,
  tripsUpdated: PropTypes.func,
  month: PropTypes.instanceOf(moment),
  isReportConfirmed: PropTypes.bool,
};

export default Kilometers;
