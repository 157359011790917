const TRIPS = 'trips';
const KILOMETERS = 'kilometers';
const EXPENSES = 'expenses';
const ADMINISTRATION = 'administration';
const BUDGET = 'budget';
export const DATE_NEW_EXPENSES_TYPE = new Date("2025-02-01T00:00:00");
export const ROUTES = {
  LOGIN: '/login',
  PROFILE: '/profile',
  WORKLOG: '/tempo',
  BUDGET: `/${BUDGET}`,
  ADMINISTRATION: `/${ADMINISTRATION}`,
  ADMIN_BUDGET: `/${ADMINISTRATION}/${BUDGET}`,
  REPORT: '/report',
  TRIPS,
  KILOMETERS,
  EXPENSES,
  TRIPS_KILOMETERS: `/${TRIPS}/${KILOMETERS}`,
  TRIPS_EXPENSES: `/${TRIPS}/${EXPENSES}`,
  ABSENCES: '/absences',
  VIEW_ABSENCES: '/view-absences',
  CERTIFICATIONS: '/certifications',
  EARNED_CERTIFICATIONS: '/earned-certifications',
  RETROSPECTIVE_GOAL_TYPES: '/retrospective-goal-types',
  RETROSPECTIVE_GOALS: '/retrospective-goals',
  RETROSPECTIVE: '/retrospectives',
};

export const AUTH_CLIENTS = {
  i3portalBackend: 'i3portal-backend',
};

export const API = {
  TOKEN: '/oauth2/token/',
  REVOKE_TOKEN: '/oauth2/revoke_token/',
  USERS_SEND_MAIL: 'api/v1/users/sendemail',
  CURRENT_USER: 'api/v1/users/current',
  USER_LIST: 'api/v1/users/list',
  SIGNATURE: 'api/v1/users/current/signature',
  AVATAR: 'api/v1/users/current/avatar',
  USER_AVATAR: 'api/v1/users/avatar',
  TRIPS: 'api/v1/trips/',
  REPORTS: 'api/v1/reports/',
  REPORTS_PDF: 'api/v1/reports/pdf/',
  REPORTS_XLSX: 'api/v1/reports/xlsx/',
  REPORTS_CONFIRM: 'api/v1/reports/confirm/',
  REPORTS_DETAIL: 'api/v1/reports/detail/',
  REPORTS_LIST: 'api/v1/users/list/report',
  REPORTS_WORKLOGS_PREVIEW: 'api/v1/worklogs/json/report',
  REPORTS_ABSENCES_DISC: 'api/v1/absences/disc-report',
  REPORTS_ATTENDANCE_SHEET: 'api/v1/worklogs/attendance-sheet/',
  REPORTS_TICKET_RESTAURANT: 'api/v1/worklogs/ticket-report/',
  SYSTEM: 'api/v1/system',
  SYSTEM_BUDGETS: 'api/v1/budgets/',
  SYSTEM_VERSION: 'api/v1/version/',
  EXPENSES: 'api/v1/expenses/',
  EXPENSES_BY_TYPE: 'api/v1/expenses-by-type/',
  EXPENSES_BY_TYPE_ADMIN: 'api/v1/expenses-by-type/administration',
  TRAINING_DAYS: 'api/v1/trainingDay/',
  TRAINING_EXPENSES: 'api/v1/trainingBudget/',
  TRAINING_DAYS_ADMIN: 'api/v1/trainingDay/administration/',
  TRAINING_EXPENSES_ADMIN: 'api/v1/trainingBudget/administration/',
  UTILITIES: 'api/v1/utilities/',
  CALENDAR: 'api/v1/calendar/',
  ABSENCES: 'api/v1/absences/',
  WORKLOGS: 'api/v1/worklogs/',
  ISSUES: 'api/v1/issues/',
  PROJECTS: 'api/v1/projects/',
  ME_ISSUES: 'api/v1/me/issues/',
  ME_PROJECTS: 'api/v1/me/projects/',
  CERTIFICATIONS: 'api/v1/certifications/',
  CERTIFICATIONS_EMITTERS: 'api/v1/certifications/emitters/',
  CERTIFICATIONS_CATEGORIES: 'api/v1/certifications/categories/',
  EARNED_CERTIFICATIONS: 'api/v1/earned-certifications/',
  RETROSPECTIVE_GOAL_TYPES: 'api/v1/retrospective-goal-types/',
  RETROSPECTIVE_GOALS: 'api/v1/retrospective-goals/',
  RETROSPECTIVE: 'api/v1/retrospective/',
  USERS: 'api/v1/users/',
  COMPANIES: 'api/v1/companies/',
  USER_RETROSPECTIVE: 'api/v1/users/retrospective',
};

export const EXPENSE_BONUS_SMART_WORKING = 'bonus_smart_working';
export const EXPENSE_BONUS_BOOKS = 'bonus_books';
const EXPENSE_TYPE_CAN_HAVE_COMPANY_INVOICE_OLD = {
  highway_parking: true,
  room_and_board: true,
  travel: true,
  entertainment_expense: true,
  it_equipment: true,
  consumable: true,
  training_expenses: true,
  [EXPENSE_BONUS_SMART_WORKING]: false,
  [EXPENSE_BONUS_BOOKS]: false,
  other: true,
  cash_advance: false,
};

const EXPENSE_TYPE_KM = {
  customers: true,
  training: true,
  training_budget: true,
  corporate_events: true,
  marketing_presale: true,
  other_new: true,
};

const EXPENSE_TYPE_CAN_HAVE_COMPANY_INVOICE = {
  ...EXPENSE_TYPE_KM,
  books_budget: false,
  smart_working: false,
  advance: false,
};


export const EXPENSES_TYPES = Object.keys(EXPENSE_TYPE_CAN_HAVE_COMPANY_INVOICE);
export const EXPENSES_TYPES_OLD = Object.keys(EXPENSE_TYPE_CAN_HAVE_COMPANY_INVOICE_OLD);
export const EXPENSES_TYPES_KM = Object.keys(EXPENSE_TYPE_KM);

export const canHaveCompanyInvoice = (expenseType) =>
  EXPENSE_TYPE_CAN_HAVE_COMPANY_INVOICE[expenseType] || EXPENSE_TYPE_CAN_HAVE_COMPANY_INVOICE_OLD[expenseType];

export const PERMISSIONS = {
  ADMINISTRATION: 'administration',
  BUDGET_ADMINISTRATION: 'budget_administration',
  CERTIFICATION_ADMINISTRATION: 'certification_administration',
  RETROSPECTIVE_ADMINISTRATION: 'retrospective_administration',
  DATA_ANALYSIS: 'data_analysis',
  VIEW_ABSENCES: 'view_absences',
  VIEW_EARNED_CERTIFICATIONS: 'view_earned_certifications',
};

export const REPORT_GROUP_BY = {
  NONE: 0,
  PROJECT: 1,
  ISSUE: 2,
};

export const EARNEDCERTIFICATIONS_GROUP_BY = {
  NONE: 0,
  CERTIFICATION: 1,
}

export const USERS_PARAM_NAME = 'u';
